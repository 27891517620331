import { breakpoints } from "../core/constants";

class DropdownMenu {
    constructor(dropdownMenuItems) {
        if (!dropdownMenuItems) return;

        this.dropdownMenuItems = dropdownMenuItems;

        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.dropdownMenuItems.forEach(item => {
            const menuLink = item.querySelector('.menu__link');
            const returnButton = item.querySelector('.menu--submenu .menu__item--return');

            if(!menuLink && !returnButton) return;

            menuLink.addEventListener('click', this.toggleDropdown.bind(this));
            returnButton.addEventListener('click', this.toggleDropdown.bind(this));
        });
    }

    toggleDropdown(e) {
        if(window.matchMedia(`(max-width: ${breakpoints.lg}px)`).matches) {
            e.preventDefault();

            const menuItem = e.target.closest('.menu__item--parent');
            const dropdownMenu = menuItem.querySelector('.menu--submenu');

            dropdownMenu.classList.toggle('is-active');
        }
    }
}


const dropdownMenuItems = [...document.querySelectorAll('.menu__item--parent')];

if (dropdownMenuItems) {
    new DropdownMenu(dropdownMenuItems);
}
