import BEM from "../utilities/bem";

class Accordion {
    constructor(accordion) {
        if (!accordion) return;

        this.accordion = accordion;
        this.baseClass = 'accordion';
        this.activeClass = 'is-active';
        this.accordionItemClass = BEM.element_class(this.baseClass, 'item');
        this.accordionHeaderClass = BEM.element_class(this.baseClass, 'header');
        this.accordionBodyClass = BEM.element_class(this.baseClass, 'body');
        this.accordionContentClass = BEM.element_class(this.baseClass, 'content');

        this.init();
    }

    init() {
        this.accordionItems = [...this.accordion.querySelectorAll(`.${this.accordionItemClass}`)];

        this.setDefaultState();
        this.registerEvents();
    }

    setDefaultState() {
        this.accordionItems.forEach((item) => {
            const content = item.querySelector(`.${this.accordionContentClass}`);
            const body = item.querySelector(`.${this.accordionBodyClass}`);
            const contentHeight = content.offsetHeight;
            body.style.setProperty('--active-height', `${contentHeight}px`);
        });

        if (this.accordion.hasClass(BEM.modifier_class(this.baseClass, 'footer')) && window.matchMedia('(min-width: 992px)').matches) {
            this.showFirst();
        }
        else {
            if (!this.accordion.hasClass(BEM.modifier_class(this.baseClass, 'flush'))) {
                this.showFirst();
            }
        }
    }

    registerEvents() {
        this.clickEvents();
        this.resizeEvents();
    }

    clickEvents() {
        this.accordionItems.forEach((item) => {
            const collapseButton = item.querySelector(`.${this.accordionHeaderClass}`);

            if(collapseButton) {
                collapseButton.addEventListener('click', (e) => {
                    this.toggleAccordion(e);
                });
            }
        });
    }

    resizeEvents() {
        window.addEventListener('resize', () => {
            this.setDefaultState();
        });
    }

    toggleAccordion(e) {
        e.preventDefault();

        const target = e.target || e.srcElement;

        if (!target.closest(`.${this.accordionItemClass}`).hasClass(this.activeClass)) {
            this.setActiveItem(target.closest(`.${this.accordionItemClass}`));
        } else {
            this.removeActiveItem(target.closest(`.${this.accordionItemClass}`));
        }
    }

    showFirst() {
        this.setActiveItem(this.accordionItems[0]);
    }

    setActiveItem(element) {
        element.classList.add(this.activeClass);
    }

    removeActiveItem(element) {
        element.classList.remove(this.activeClass);
    }
}

const accordions = [...document.querySelectorAll('[data-accordion]')];

accordions.forEach((accordion) => {
    new Accordion(accordion);
});
