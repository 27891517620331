// Extending HTMLElement prototype to add a custom method
HTMLElement.prototype.selectFromParent = function(parentSelector, childSelector) {
    // Get the closest ancestor matching the parentSelector
    const closestParent = this.closest(parentSelector);
    // If the closest parent is found, query for the child element within it
    if (closestParent) {
        return closestParent.querySelector(childSelector);
    }
    return null;  // Return null if the closest parent or child element is not found
};

HTMLElement.prototype.selectAllFromParent = function(parentSelector, childSelector) {
    // Get the closest ancestor matching the parentSelector
    const closestParent = this.closest(parentSelector);
    // If the closest parent is found, query for the child element within it
    if (closestParent) {
        return closestParent.querySelectorAll(childSelector);
    }
    return null;  // Return null if the closest parent or child element is not found
};

HTMLElement.prototype.hasClass = function(class_name) {
    return this.classList.contains(class_name);
};
