import { breakpoints } from "../core/constants";

export const effects = [
    'slide',
    'fade',
    'cube',
    'coverflow',
    'flip',
    'creative',
    'cards'
];

export const swiperSizes = {
    fourth: 4,
    half: 2,
    third: 3,
    full: 1,
};

export const swiperOptions = {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    autoHeight: false,
    createElements: true,
    watchOverflow: true,
    slideClass: 'swiper__slide',
    wrapperClass: 'swiper__wrapper',

    lazy: {
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
    },

    speed: 500,
    spaceBetween: 40,
    effect: effects[0],
    grabCursor: true,

    breakpoints: {
        [`${breakpoints.xs}`]: {
            slidesPerView: 1.2,
            centeredSlides: true,
        },
        [`${breakpoints.lg}`]: {
            slidesPerView: 2,
            centeredSlides: false,
        }
    }
};
