export default {
    template: 'components/pagination.twig',
    props: {
        currentPage: {
            type: Number,
            required: true,
            default: 1,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        maxVisiblePages: {
            type: Number,
            default: 3, // Adjust as needed
        },
    },
    computed: {
        pages() {
            const range = [];
            const maxVisiblePages = this.maxVisiblePages;
            const currentPage = this.currentPage;
            const totalPages = this.totalPages;

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    range.push(i);
                }
            } else {
                let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                if (endPage - startPage + 1 < maxVisiblePages) {
                    startPage = Math.max(1, endPage - maxVisiblePages + 1);
                }

                if (startPage > 1) {
                    range.push(1);
                    if (startPage > 2) {
                        range.push('...');
                    }
                }

                for (let i = startPage; i <= endPage; i++) {
                    range.push(i);
                }

                if (endPage < totalPages) {
                    if (endPage < totalPages - 1) {
                        range.push('...');
                    }
                    range.push(totalPages);
                }
            }

            return range;
        },
    },
    // emits: ['pageChanged'],
    methods: {
        changePage(page) {
            if (typeof page === 'number' && page !== this.currentPage && page >= 1 && page <= this.totalPages) {
                this.$emit('page-changed', page);
            }            
        },
        isCurrentPage(page) {
            return page === this.currentPage;
        },
        isFirstPage() {
            return this.currentPage === 1;
        },
        isLastPage() {
            return this.currentPage === this.totalPages;
        },
        isEllipsis(page) {
            return page === '...';
        },
    },
};
