class GridHelper {
    toggle() {
        const appDev = document.querySelector('#app-dev');
        if (!appDev) return;

        const overlayGrid = document.querySelector('.container--overlay-grid');
        overlayGrid ? window.GridHelper.destroy() : window.GridHelper.active();
    }

    active() {
        document.body.insertAdjacentHTML(
            'beforeEnd',
            `
            <div class="container container--overlay-grid">
                <div class="row">
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                </div>
            </div>
        `
        );

        localStorage.setItem('grid-helper', true);
    }

    destroy() {
        document.querySelector('.container--overlay-grid').remove();

        localStorage.setItem('grid-helper', false);
    }
}

const gridHelper = new GridHelper();
window.GridHelper = gridHelper;

if (localStorage.getItem('grid-helper') == 'true' && document.querySelector('#app-dev')) {
    window.GridHelper.active();
}

document.addEventListener('keydown', (e) => {
    // Letter G = 71

    if (e.ctrlKey && e.keyCode === 71) {
        gridHelper.toggle();
    }
});
