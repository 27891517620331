import { vuePreconfig } from '@pixelexpress/vue/src/vuePreconfig';
import { watch, reactive, computed } from 'vue';

// Components
import pagination from '../components/vue/pagination';

const queryString = window.location.href;
const url = new URL(queryString);
const paginationPageParameter = 'page';
const currentPage = url.searchParams.get(paginationPageParameter);

const state = reactive({
    posts: [],
    currentPage: parseInt(currentPage) ? parseInt(currentPage) : 1,
    itemsPerPage: parseInt(posts_per_page), // eslint-disable-line
});

const component = {
    template: 'components/post-grid.twig',
    components: {
        pagination,
    },
    setup() {
        fetch('/wp-json/wp/v2/posts?_embed', {
            cache: 'default',
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                state.posts = data;
            });

        const getPosts = computed(() => {
            return state.posts;
        });

        const paginatedPosts = computed(() => {
            if (!state.posts) return [];
            const start = (state.currentPage - 1) * state.itemsPerPage;
            const end = start + state.itemsPerPage;
            return state.posts.slice(start, end);
        });

        const itemsPerPage = computed(() => {
            return state.itemsPerPage;
        });

        const currentPage = computed(() => {
            return state.currentPage;
        });

        const totalPages = computed(() => {
            return state.posts ? Math.ceil(state.posts.length / state.itemsPerPage) : 0;
        });

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                state.currentPage = page;
                url.searchParams.set(paginationPageParameter, page !== 1 ? page : '');
                window.history.pushState(null, null, url.href);
            }
        };

        watch(() => state.currentPage, (newPage) => {
            if (newPage >= 1 && newPage <= totalPages.value) {
                changePage(newPage);
            }
        }, { immediate: true });

        return {
            getPosts,
            paginatedPosts,
            currentPage,
            totalPages,
            changePage,
            itemsPerPage,
        };
    }
}

vuePreconfig.createApp('#archive', component);
