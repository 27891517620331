class EditorManager {
    constructor(selector) {
        this.editorElements = document.querySelectorAll(selector);
    }

    removeTagNameFromEditor(charToRemove) {
        if (!this.editorElements) {
            return;
        }

        this.editorElements.forEach(editor => {
            this.recurse(editor, charToRemove);
        });
    }

    removeCharacter(char, el) {
        const chars = el.querySelectorAll(char);

        if (chars) {
            chars.forEach(char => {
                char.remove();
            });
        }
    }

    recurse(el, charToRemove) {
        let editorChildren = [...el.children];

        editorChildren = editorChildren.filter(child => child.tagName !== "P");

        if (editorChildren) {
            for (let i = 0; i < editorChildren.length;) {
                this.removeCharacter(charToRemove, editorChildren[i]);
                this.recurse(editorChildren[i], charToRemove);
                i++;
            }
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth < 920) {
        const editorManager = new EditorManager('.editor');
        editorManager.removeTagNameFromEditor('br');
    }
});
