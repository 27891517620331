export default class Modal {
    constructor(modal, options = {}) {
        if (!modal) return;

        this.modal = modal;
        this.modalTogglers = [...document.querySelectorAll('[data-modal-toggler]')];
        this.activeClass = 'is-active';
        this.closeModalToggler = this.modal.querySelector('.modal__close');
        this.defaultOptions = {
            delay: '0s',
            duration: '0.9s',
        };
        this.options = {
            ...this.defaultOptions,
            ...options,
        };

        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.clickEvents();
        this.keyBoardEvents();
    }

    clickEvents() {
        this.modalTogglers.forEach((toggler) => {
            toggler.addEventListener('click', (e) => {
                e.preventDefault();

                this.openModal(toggler.getAttribute('data-modal-target'));
            });
        });

        this.modal.addEventListener('click', () => {
            this.closeModal(this.modal);
        });

        this.modal.querySelector('.modal__content').addEventListener('click', (e) => {
            e.stopPropagation();
        });

        this.closeModalToggler.addEventListener('click', () => {
            this.closeModal(this.modal);
        });
    }

    keyBoardEvents() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.closeModal(this.modal);
            }
        });
    }

    openModal(elem) {
        this.fireEvent('modal.open');
        document.querySelector(elem).classList.add(this.activeClass);
        this.transitionEndEvent('modal.open.end');
    }

    closeModal(elem) {
        this.fireEvent('modal.close');
        elem.classList.remove(this.activeClass);
        this.transitionEndEvent('modal.close.end');
    }

    transitionEndEvent(eventName) {
        this.modal.querySelector('.modal__content').addEventListener('transitionend', () => {
            this.fireEvent(eventName);

            // Reset options to their default values after the modal is closed
            if (eventName === 'modal.close.end') {
                this.resetOptions();
            }
        });
    }

    fireEvent(eventName, data = {}) {
        const event = new CustomEvent(eventName, {
            detail: {
                instance: this,
                modalElement: this.modal,
                ...data,
            },
        });

        document.dispatchEvent(event);
    }
}
